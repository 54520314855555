<template>
  <main class="page404">
  <div class="holder">
    <h1>{{ $t('404.something-wrong') }}</h1>
    <p>{{ $t('404.not-found') }}</p>

    <a class="to-main" @click="goToMain">{{ $t('404.go-to-main') }}</a>
  </div>
  </main>
</template>

<script>
  export default {
    name: 'Error404',
    title: 'Error',
    data() {
      return {

      }
    },
    methods: {
      goToMain() {
        this.$router.push({ name: 'objects' });
      }
    }
  };
</script>

<style scoped>

</style>
